<div class="col-md-12">
    <div class="error-template">
        <h3>Oops!</h3>
        <h2>404 Not Found</h2>
        <div class="error-details mb-3">
            Sorry, Requested page not found!
        </div>
        <div class="error-actions">
            <a routerLink="/home" class="btn btn-danger btn-lg">
                Go Back to Home
            </a>
        </div>
    </div>
</div>