
<div class="row">
	<div class="col-sm-3">
		<h3>Filters</h3>
		<button type="button" class="btn btn-primary" (click)="resetFilter()">Reset</button>
		<br><br>


		<div class="accordion" id="accordionExample">

		<!--Start MealType-->
		  <div class="card z-depth-0 bordered">
		    <div class="card-header" id="headingThree">
		      <h5 class="mb-0">
		        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree"
		          aria-expanded="false" aria-controls="collapseThree">
		          MealType
		        </button>
		      </h5>
		    </div>
		    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
		      data-parent="#accordionExample">
		      <div class="card-body">
		        <ng-container *ngFor="let mealType of mealTypes">
					<span class="badge badge-pill badge-primary" (click)="filterByMealType(mealType)">{{mealType}}</span>
					<br>
				</ng-container>
		      </div>
		    </div>
		  </div> 
		  <!-- End MealType-->

		  <!--Start DishType-->
		  <div class="card z-depth-0 bordered">
		    <div class="card-header" id="headingFour">
		      <h5 class="mb-0">
		        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour"
		          aria-expanded="false" aria-controls="collapseFour">
		          DishType
		        </button>
		      </h5>
		    </div>
		    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
		      data-parent="#accordionExample">
		      <div class="card-body">
		        <ng-container *ngFor="let dishType of dishTypes">
					<span class="badge badge-pill badge-primary" (click)="filterByDishType(dishType)">{{dishType}}</span>
					<br>
				</ng-container>
		      </div>
		    </div>
		  </div> 
		  <!-- End DishType-->

		  <!--Start Rating-->
		  <div class="card z-depth-0 bordered">
		    <div class="card-header" id="headingOne">
		      <h5 class="mb-0">
		        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
		          aria-expanded="false" aria-controls="collapseOne">
		          Rating
		        </button>
		      </h5>
		    </div>
		    <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
		      data-parent="#accordionExample">
		      <div class="card-body">
		        <ng-container *ngFor="let rating of ratings">
				    <!-- Default unchecked disabled -->
					<!-- <div class="custom-control custom-checkbox">
					  <input type="checkbox" class="custom-control-input" id="defaultUnchecked{{rating}}" (click)="filterByRating(rating)">
					  <label class="custom-control-label" for="defaultUnchecked{{rating}}">{{rating}}+</label>
					</div> -->
					
					<span class="badge badge-pill badge-primary" (click)="filterByRating(rating)">{{rating}}+</span>
					<br>
				</ng-container>
		      </div>
		    </div>
		  </div> 
		  <!-- End Rating-->

		  <!--Start Difficulty-->
		  <div class="card z-depth-0 bordered">
		    <div class="card-header" id="headingTwo">
		      <h5 class="mb-0">
		        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo"
		          aria-expanded="false" aria-controls="collapseOne">
		          Difficulty
		        </button>
		      </h5>
		    </div>
		    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
		      data-parent="#accordionExample">
		      <div class="card-body">
		        <ng-container *ngFor="let difficulty of difficulties">
					<span class="badge badge-pill badge-primary" (click)="filterByDifficulty(difficulty)">{{difficulty}}</span>
					<br>
				</ng-container>
		      </div>
		    </div>
		  </div> 
		  <!-- End Difficulty-->

		</div>
	</div>

	<div class="col-sm-9">
		<div class="row">
		    <div class="col-sm-4" *ngFor="let recipes of recipes" >
		        <div class="card mb-3">
		            <div class="card-body">
		                <h5 class="card-title">{{recipes.name}}</h5>
		            	<img src="{{recipes.imagePath}}" class="rounded mx-auto img-preview" alt="...">
		            	<br>
		                <ngb-rating [rate]="recipes.rating"></ngb-rating>
		                <p class="card-text">
							<a [routerLink]="['/recipe-detail/', recipes.id]" class="stretched-link"></a>
		                </p>
		            </div>
		            <div class="card-footer">
		            	<ng-container *ngFor="let mt of recipes.mealType">
		            		&nbsp;<span class="badge badge-pill badge-primary">{{mt}}</span>
		            	</ng-container>
		            	<ng-container *ngFor="let dt of recipes.dishType">
		            		&nbsp;<span class="badge badge-pill badge-primary">{{dt}}</span>
		            	</ng-container>
		            	&nbsp;<span class="badge badge-pill badge-primary">{{recipes.difficulty}}</span>
		            	
		            </div>
		        </div>
		    </div>
		</div>
	</div>
</div>