<nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-5">
  <div class="container">
    <a class="navbar-brand" href="#">{{ title }}</a>

    <ul class="nav nav-pills">
      <!-- <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/home">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/about">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/recipes">Recipes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/contact">Contact</a>
      </li> -->
    </ul>
  </div>
</nav>

<div class="container text-center">
  <router-outlet></router-outlet>
</div>