import { Component, OnInit } from '@angular/core';

import { Recipe, DishType, MealType, Difficulty } from './recipes.model';
import { RecipeService } from './recipes.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css'],
  providers: [RecipeService, NgbRatingConfig]
})
export class RecipesComponent implements OnInit {
	recipes: Recipe[];

  eDishType = DishType;
  dishTypes = [];

  eMealType = MealType;
  mealTypes = [];

  ratings : Number[];

  eDifficulty = Difficulty;
  difficulties = [];


  constructor(private recipeService: RecipeService, private config: NgbRatingConfig) { 
    // customize default values of ratings used by this component tree
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit() {
    this.recipes = this.recipeService.getRecipes();
    this.dishTypes = Object.values(this.eDishType);
    this.mealTypes = Object.values(this.eMealType);
    this.ratings = [0, 1, 2, 3, 4, 5];
    this.difficulties = Object.values(this.eDifficulty);
  }

  resetFilter(){
    this.recipes = this.recipeService.getRecipes();
  }

  filterByDishType(dishType: DishType){
    this.recipes = this.recipeService.getRecipesByDishType(dishType);//, this.recipes);
  }

  filterByMealType(mealType: MealType){
    this.recipes = this.recipeService.getRecipesByMealType(mealType);//, this.recipes);
  }

  filterByRating(rating: number){
    this.recipes = this.recipeService.getRecipesByRating(rating);//, this.recipes);
  }

  filterByDifficulty(difficulty: Difficulty){
    this.recipes = this.recipeService.getRecipesByDifficulty(difficulty);//, this.recipes);
  }

}