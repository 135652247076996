import { EventEmitter } from '@angular/core';

import { Recipe, DishType, MealType, Tool, Difficulty, Ingredient, Direction, DirectionD, Unit} from './recipes.model';

var temp = new Recipe(1, //id
             "Fake News Pasta", //name
             [DishType.Pasta], 
             [MealType.Lunch, MealType.Dinner],
             "Easy to make", //description
             "", //imagePath
             30, //prepTimeInMinutes
             30, //cookTimeInMinutes
              8, //servingSize
             [Tool.Oven_Gloves], //tools
             [], //ingredients
             [], //directions
             5, //rating
             Difficulty.Easy); //difficulty

var DoubleCheeseBurger = new Recipe(0, "Double Cheeseburger", 
             [DishType.Sandwich, DishType.Beef], 
             [MealType.Lunch, MealType.Dinner], 
             "Easy to make", 
             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger.JPG",
             1, 10, 1,
             [Tool.Air_Fryer, Tool.Skillet, Tool.Spatula],
             [new Ingredient(1, Unit.No,"bun"),
              new Ingredient(1, Unit.tbsp,"oil"),
              new Ingredient(2, Unit.No,"beef patty"),
              new Ingredient(2, Unit.tsp,"black pepper"),
              new Ingredient(2, Unit.slice,"cheese"),
              new Ingredient(1, Unit.tbsp,"mustard"),
              new Ingredient(1, Unit.tbsp,"ketchup")],
             [new Direction("Toast buns",
                            [Tool.Air_Fryer],
                            [new Ingredient(1, Unit.No,"bun", 1)], 
                            40,
                            ["../../assets/images/DoubleCheeseburger/Bun_Prep_AirFryer.JPG", 
                             "../../assets/images/DoubleCheeseburger/Bun_Done.JPG"],
                            [new DirectionD("Place the bun in the AirFryer at 400F for 20 sec.", 20),
                             new DirectionD("Flip and wait for an additional 20 secs.", 20),
                             new DirectionD("Take the buns out and put on a plate.")]),
             new Direction("Cook burgers",
                            [Tool.Skillet, Tool.Spatula],
                            [new Ingredient(1, Unit.tbsp,"oil",2),
                             new Ingredient(2, Unit.No,"beef patty",4),
                             new Ingredient(2, Unit.tsp,"black pepper",4),], 
                            540,
                            ["../../assets/images/DoubleCheeseburger/DoubleCheeseburger_1.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger_2.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger_3.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger_4.JPG"],
                            [new DirectionD("Set the stove to Medium (4-5). Place skillet on stove. Add oil and spread it.",0),
                             new DirectionD("Add the beef patties in the skillet. Add the black pepper on top. Cook for 4 minutes.",4*60),
                             new DirectionD("Flip and add black pepper. Cook for another 4 minutes.",4*60),
                             new DirectionD("Flip once more. Add cheese on top of the patties. Cover for 1 minute.",60)]),
              new Direction("Assemble",
                            [Tool.Spatula],
                            [new Ingredient(1, Unit.No,"bun",1),
                             new Ingredient(2, Unit.No,"beef patty",2),
                             new Ingredient(1, Unit.tbsp,"mustard",1),
                             new Ingredient(1, Unit.tbsp,"ketchup",1)], 
                            0,
                            ["../../assets/images/DoubleCheeseburger/DoubleCheeseburger_6.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger_7.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger_8.JPG", 
                             "../../assets/images/DoubleCheeseburger/DoubleCheeseburger.JPG"],
                            [new DirectionD("Use the spatula and place the beef patties on top of the bottom bun. Add mustard and ketchup. Place top bun on top.")]),
             ],
             4.5,
             Difficulty.Easy);

export class RecipeService {
    recipesSelected : Recipe[];

    private recipes: Recipe[] = [
        DoubleCheeseBurger,
  		temp,
  	];

    getRecipes() {
        return this.recipes;
    }

    setFilteredRecipes(recipes : Recipe[]) {
        return this.recipesSelected = recipes;
    }

    getFilteredRecipes() {
        return this.recipesSelected == null ? this.recipes : this.recipesSelected;
    }

    getRecipeById(id: string) {
        return this.recipes[id];
    }

    getRecipesByDishType(dishType: DishType, inputRecipe?: Recipe[]) {
        var recipeToFilter = inputRecipe == null ? this.recipes : inputRecipe;
        return recipeToFilter.filter(recipe => recipe.dishType.includes(dishType));
    }

    getRecipesByMealType(mealType: MealType) {
        return this.recipes.filter(recipe => recipe.mealType.includes(mealType));
    }

    getRecipesByRating(rating: number, inputRecipe?: Recipe[]) {
        var recipeToFilter = inputRecipe == null ? this.recipes : inputRecipe;
        return recipeToFilter.filter(recipe => recipe.rating >= rating);
    }

    getRecipesByDifficulty(difficulty: Difficulty) {
        return this.recipes.filter(recipe => recipe.difficulty == difficulty);
    }

}