import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Recipe} from '../recipes/recipes.model';
import { RecipeService } from '../recipes/recipes.service';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.css'],
  providers: [RecipeService]
})

export class RecipeDetailComponent {
  recipe_id: string;
  recipe: Recipe; 
  initialServing : number;
  modifiedServingSize:number;

  constructor(private actRoute: ActivatedRoute, private recipeService: RecipeService) {
    this.recipe_id = this.actRoute.snapshot.params.id;
    this.recipe = this.recipeService.getRecipeById(this.recipe_id);
    this.initialServing = this.recipe.servingSize;
    this.modifiedServingSize = this.recipe.servingSize;
  }

  ngOnInit() {
  }


  increaseServingSize() { 
    this.modifiedServingSize += 1; 
    let multiplicationFactor = this.modifiedServingSize/this.initialServing;
    this.recipe.servingSize = this.modifiedServingSize;

    this.setServingSizeIngredient(multiplicationFactor);
  }

  decreaseServingSize() { 
    if (this.modifiedServingSize > 1)
    {
      this.modifiedServingSize -= 1;
      let multiplicationFactor = this.modifiedServingSize/this.initialServing;
      this.recipe.servingSize = this.modifiedServingSize;

      this.setServingSizeIngredient(multiplicationFactor);
    }
  }

  setServingSizeIngredient(multiplicationFactor: number)
  {

    let ingredients = this.recipe.ingredients;
    for(let i = 0; i < ingredients.length; i++)
    {
      ingredients[i].amount = ingredients[i].initialAmount * multiplicationFactor;
    }

    let sumDirectionTimeInSeconds = 0;
    let directions = this.recipe.directions;
    for (let i = 0; i < directions.length; i++)
    {
      let directionIngredients = directions[i].ingredients;
      let repeat = 0;
      for(let j = 0; j < directionIngredients.length; j++)
      {
        let newAmount = directionIngredients[j].initialAmount * multiplicationFactor;
        if (newAmount > directionIngredients[j].maxAmountPerDirection)
        {
           directionIngredients[j].amount = directionIngredients[j].maxAmountPerDirection;
           repeat = Math.ceil(newAmount/directionIngredients[j].maxAmountPerDirection); 
        }
        else
        {
          directionIngredients[j].amount = newAmount;
        }
      }

      // Check if the direction needs to be repeated
      if (repeat > 1)
      {
        directions[i].nameSuffix = " (Repeat x" + repeat+")"
        directions[i].totalTime = directions[i].time * repeat;
      }
      else
      {
        directions[i].nameSuffix = "";
        directions[i].totalTime = directions[i].time;
      }

      // Get total time 
      sumDirectionTimeInSeconds += directions[i].totalTime;
    }

    this.recipe.cookTimeInMinutes = Math.ceil(sumDirectionTimeInSeconds/60);

  }

  formatTime(seconds: number)
  {
    let hour = Math.floor(seconds/3600);
    let tmp = seconds % 3600;
    let minute = Math.floor(tmp/60);
    let second = seconds % 60;

    let result = "";

    if (hour > 0)
    {
      result += hour + " hr ";
    }
    if (minute > 0)
    {
      result += minute + " min ";
    }
    if (second > 0)
    {
      result += second + " sec ";
    }

    return result;
  }

}