<div class="row">
  <div class="card col-md-12">
      <h2>{{recipe.dishType}}</h2>
      <hr/>
      <h1>{{recipe.name}}</h1>
      <hr/>
      <div class="row">
        <div class="card col-md-3">
          <!-- INGREDIENTS -->
          <div>
            <h3>
              <span class="badge badge-primary">Ingredients 
                <span class="badge badge-light">{{recipe.ingredients.length}}</span>
              </span>
            </h3>
            <ng-container *ngFor="let ingredient of recipe.ingredients; index as i">
              <div class="text-left">
                &nbsp;&nbsp;&nbsp;&nbsp; <!--Add spaces otherwise below is not contained in div-->
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck{{i}}">
                <label class="form-check-label" for="defaultCheck{{i}}">
                  {{ingredient.amount}} {{ingredient.unit}} {{ingredient.name}}
                </label>
              </div>
              <hr>
            </ng-container>
          </div>

          <!-- TOOLS -->
          <div>
            <h3>
              <span class="badge badge-info">Tools 
                <span class="badge badge-light">{{recipe.tools.length}}</span>
              </span>
            </h3>
            <ng-container *ngFor="let tool of recipe.tools">
              <p class="text-left">{{tool}}</p>
              <hr>
            </ng-container>
          </div>
        </div>


        <div class="card col-md-9">
          <!-- TIMES -->
          <hr>
          <div class="row">
            <div class="col-md-4">
              <h5><span class="badge badge-secondary">Prep Time</span></h5>
              <span>{{recipe.prepTimeInMinutes}} mins</span>
            </div>
            <div class="col-md-4">
              <h5><span class="badge badge-warning">Cook Time</span></h5>
              <span>{{recipe.cookTimeInMinutes}} mins</span>
            </div>
            <div class="col-md-4">
              <h5><b>Serving Size</b></h5>
              <span>{{recipe.servingSize}}
                <img class="img-border" src="/assets/img/icons/arrow-up.svg" (click)="increaseServingSize()" alt="" width="20" height="20" title="+1">
                <img class="img-border" src="/assets/img/icons/arrow-down.svg" (click)="decreaseServingSize()" alt="" width="20" height="20" title="-1">
              </span>
            </div>
          </div>
          
          <hr>

          <div>
            <img src="{{recipe.imagePath}}" class="rounded mx-auto img-thumbnail" alt="...">

            <!-- DIRECTIONS -->
            <h3>Directions</h3>
            <ng-container *ngFor="let direction of recipe.directions">
              <hr>
              <h5>{{direction.name}}{{direction.nameSuffix}} <span class="badge badge-warning">{{formatTime(direction.totalTime)}}</span></h5> 
              <span *ngFor="let ingredient of direction.ingredients" class="badge badge-pill badge-primary">
                {{ingredient.amount}} {{ingredient.unit}} {{ingredient.name}}
              </span>
              &nbsp;<span *ngFor="let tool of direction.tools" class="badge badge-pill badge-info">{{tool}}</span>
              &nbsp;<span class="badge badge-warning">{{formatTime(direction.time)}}</span>

              <div>
                <div class="column">
                  <br>
                  <ng-container *ngFor="let imagePath of direction.imagePaths">
                    &nbsp;<img src="{{imagePath}}" class="rounded img-direction">
                  </ng-container>
                </div>
              </div>

              <br>
              <div>
                <ol>
                  <ng-container *ngFor="let description of direction.descriptions">
                    <li class="text-left">{{description.description}} 
                      <app-countdown-timer *ngIf="description.countdown" [secondsInput]="description.countdown"></app-countdown-timer>
                    </li>
                  </ng-container>    
                </ol>
              </div>

            </ng-container>
          </div>

        </div> <!-- End Right Container-->
      </div>
  </div>
</div>

<br>

<div class="error-actions">
  <a routerLink="/recipes" class="btn btn-danger btn-lg">Go Back to Recipes</a>
</div>

<br>