import { Component, OnDestroy, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  template: `<span class="seconds">
  <b>{{ seconds }} sec </b>
  <img class="img-border" src="/assets/img/icons/play-fill.svg" (click)="start()" alt="" width="20" height="20" title="Start">
  <img class="img-border" src="/assets/img/icons/pause-fill.svg" (click)="pause()" alt="" width="20" height="20" title="Pause">
  <img class="img-border" src="/assets/img/icons/arrow-counterclockwise.svg" (click)="restart()" alt="" width="20" height="20" title="Restart">
  </span>`
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  intervalId = 0;

  @Input() secondsInput: number;
  seconds: number;

  clearTimer() { clearInterval(this.intervalId); }

  ngOnInit()    { this.seconds = this.secondsInput; }
  ngOnDestroy() { this.clearTimer(); }

  start() { this.countDown(); }
  pause()  { this.seconds = this.seconds; this.clearTimer();}
  restart() { this.seconds = this.secondsInput; this.pause();}

  private countDown() {
    // this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      if (this.seconds < 0) { this.seconds = 0; } 
    }, 1000);
  }
}