export class Recipe {
  id: number;
  name: string;
  dishType: DishType[];
  mealType: MealType[];
  description : string;
  imagePath: string;

  prepTimeInMinutes: number;
  cookTimeInMinutes: number;
  servingSize: number;

  tools: Tool[];
  ingredients: Ingredient[];
  directions: Direction[];

  rating: number;
  difficulty: Difficulty;

  constructor(  id: number,
                name: string,
                dishType: DishType[],
                mealType: MealType[],
                description : string,
                imagePath: string,

                prepTimeInMinutes: number,
                cookTimeInMinutes: number,
                servingSize: number,

                tools: Tool[],
                ingredients: Ingredient[],
                directions: Direction[],

                rating: number,
                difficulty: Difficulty)
  {
    this.id=id;
    this.name=name;
    this.dishType=dishType;
    this.mealType=mealType;
    this.description=description;
    this.imagePath=imagePath;

    this.prepTimeInMinutes=prepTimeInMinutes;
    this.cookTimeInMinutes=cookTimeInMinutes;
    this.servingSize=servingSize;

    this.tools=tools;
    this.ingredients=ingredients;
    this.directions=directions;

    this.rating=rating;
    this.difficulty=difficulty;

    if (this.imagePath.length <=0){
      this.imagePath = "../../assets/images/Placeholder.JPG";
    }

  }
};

export enum DishType {
    Bbq="Bbq",
    Beef="Beef",

    Cake="Cake",
    Chicken="Chicken",
    Cookie="Cookie",
    Curry="Curry",

    Drink="Drink",

    Fish="Fish",

    Icecream="Ice Cream",

    Muffin="Muffin",

    Pasta="Pasta",
    Pastry="Pastry",
    Pie="Pie",
    Pizza="Pizza",
    Pork="Pork",
    Pudding="Pudding",

    Roast="Roast",
    Salad="Salad",
    Sandwich="Sandwich",
    Sauce="Sauce",
    Soup_Stew="Soup & Stew",

};

export enum Cuisine{
  American="American",

  Vietnamese="Vietnamese",
};

export enum Conditment{
  Ketchup="Ketchup",
  Mayonnaise="Mayonnaise",
  Mustard="Mustard",
  Pesto="Pesto",
  Relish="Relish",
  Sriracha="Sriracha",
  Tabasco="Tabasco",
};

export enum MealType {
  Breakfast="Breakfast",
  Lunch="Lunch",
  Dinner="Dinner",
  Dessert="Dessert"
 };

export enum Tool {
  Air_Fryer="Air Fryer",
  Baking_Sheet="Baking Sheet",
  Blender="Blender",
  Bottle_Opener="Bottle Opener",
  
  Can_Opener="Can Opener",
  Corkscrew="Corkscrew",
  Cutting_Board="Cutting Board",
  
  Garlic_Press="Garlic Press",
  Grater="Grater",
  
  Knife_Bread="Knife Bread",
  Knife_Chef="Knife Chef",
  Knife_Paring="Knife Paring",
  Knife_Sharpening="Knife Sharpening",
  
  Ladle="Ladle",
  
  Masher="Masher",
  Measuring_Cups="Measuring Cups",
  Measuring_Jug="Measuring Jug",
  Measuring_Spoons="Measuring Spoons",
  Mixing_Bowl="Mixing Bowl",
  
  Oven_Gloves="Oven Gloves",
  Ovenproof_Disk="Ovenproof Disk",
  
  Pasta_Fork="Pasta Fork",
  Peeler="Peeler",
  Pizza_Cutter="Pizza Cutter",
  Pot_Holder="Pot Holder",
  
  Roasting_Tin="Roasting Tin",
  Rolling_Pin="Rolling Pin",
  
  Saucepan="Saucepan",
  Scale="Scale",
  Scissors="Scissors",
  Sieve="Sieve",
  Skillet="Skillet",
  Slotted_Spoon="Slotted Spoon",
  Spatula="Spatula",
  Stirring_Spoon="Stirring Spoon",
  Strainer="Strainer",
  
  Thermometer="Thermometer",
  Tongs="Tongs",
  
  Whisk="Whisk",
};

export class Ingredient {
  amount: number;
  initialAmount: number;
  unit: Unit;
  name: string;
  maxAmountPerDirection?:number;

  constructor(  amount: number,
                unit: Unit,
                name: string,
                maxAmountPerDirection?:number)
  {
    this.amount=amount;
    this.initialAmount=amount;
    this.unit=unit;
    this.name=name;

    this.maxAmountPerDirection=maxAmountPerDirection;
  }

  public toString = () : string => {
    return `${this.amount} ${this.unit} ${this.name}`;
  }
};

export class Direction{
  name: string;
  nameSuffix:string;
  tools: Tool[];
  ingredients: Ingredient[];
  time: number;
  imagePaths: string[];
  descriptions: DirectionD[];

  totalTime: number;

  constructor(  name: string,
                tools: Tool[],
                ingredients: Ingredient[],
                time: number,
                imagePaths: string[],
                descriptions: DirectionD[])
  {
    this.name=name;
    this.nameSuffix="";
    this.tools=tools;
    this.ingredients=ingredients;
    this.time=time;
    this.imagePaths=imagePaths;
    this.descriptions=descriptions;

    this.totalTime=time;
  }
};

export class DirectionD{
  description: string;
  countdown?: number;
  cookTime?:number;
  prepTime?:number;

  constructor(  description: string,
                countdown?: number,
                cookTime?: number,
                prepTime?: number)
  {
    this.description=description;
    this.countdown=countdown;
    this.cookTime=cookTime;
    this.prepTime=prepTime;
  }
};

export enum Unit{
  tsp="tsp",
  tbsp="tbsp",
  floz="fl oz",
  cup="cup",
  gal="gal",
  lb="lb",
  oz="oz",
  g="g",
  kg="kg",
  C="C",
  K="F",

  No=" ",
  slice="slice",
};

export enum Difficulty{
  Easy="Easy",
  Intermediate="Intermediate",
  Hard="Hard",
};

